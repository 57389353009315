import React, { Component } from 'react'
import './index.scss'

export default class Sidebar extends Component {
    goBackTop=()=>{
        document.body.scrollTop=document.documentElement.scrollTop=0
    }
    render() {
        return (
            <div className='sidebar'>
                <div className="contact-button">
                    <span>关注公众号</span>
                    <div className="contact-wxmp"></div>
                </div>
                <div className="contact-button contact-wx">
                    <i className='icon-wx'></i>
                    <span>企微咨询</span>
                    <div className="contact-block text-c000 tal">
                        <p className="fs-24  fw-600">企业微信咨询</p>
                        <p className="fs-16 mgb10 lh1-5">现在咨询，送开店大礼包</p>
                        <i className="wx-code"></i>

                    </div>
                </div>

                <div className="contact-button contact-phone">
                    <i className='icon-phone'></i>
                    <span>电话咨询</span>
                    <div className="contact-block text-c000 fw-600 tal">
                        <p className='mgb10  fs-24 '>开店咨询 <span className='fs-16 fw-400 mgl10'>9:00-20:00</span></p>
                        <p className=' fs-20'>130 6472 1693</p>
                    </div>
                </div>

                <div className="contact-button" onClick={this.goBackTop}>
                    <span>返回顶部</span>
                </div>

            </div>
        )
    }
}
