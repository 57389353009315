import React, { Component } from 'react'
import './index.scss'

const panes = [
    {
        id: 1,
        name: "数字场馆",
        title: "数字场馆解决方案",
        content: "数字场馆解决方案，是体育场馆综合解决方案。集成场地类型管理、场地状态管理、线上预约、线上购物、会员管理和营销活动等丰富功能，助力体育场馆通过线上渠道获取客户、实现精准直销；并通过完善的线下客户管理降低人工成本，增加营销收入。",
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a5b15a139185aba362720e92dc4d78f5.png",
        icon_list: [
            {
                id: 1,
                name: "场地管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/17a4a75b5e678a0a7dc184ef490d2c37.png"
            },
            {
                id: 2,
                name: "在线预订",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/3815570fec70e7a9e9301bed75f20876.png"
            },
            {
                id: 3,
                name: "在线商城",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a01688f8aeb96e62981785863239b71c.png"
            },
            {
                id: 4,
                name: "拉新分享",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a3288d3a7b7c143bc95addeae9ce2ff8.png"
            },
            {
                id: 5,
                name: "客户管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/af682ecc2c27bd15ae7b795c6d283e07.png"
            },
            {
                id: 6,
                name: "会员储值",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/fb29ea6b503c870360ea4e92e5023d6d.png"
            },
            {
                id: 7,
                name: "员工管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/52d27aff26f6c2e898e88d35b81f3c0a.png"
            },
            {
                id: 8,
                name: "财务结算",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a0724a925d6f0d7d57a2acb2ea0d84d7.png"
            }
        ]
    },
    {
        id: 2,
        mainId:3,
        name: "智慧教培",
        title: "智慧教培解决方案",
        content: "智慧教培解决方案，专为教培培训设计。智能教务管理平台, 完善的线索跟进、学员管理，进而招生效率翻倍提升；多维度智能排课，上课日程一目了然，灵活的签到消课方式、结合数据报表，节省人力。打造学校自己的手机微官网，提升品牌知名度以及家校服务体验。",
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/bb3dcb424da0f67bd3fb88152858e1e1.png",
        icon_list: [
            {
                id: 1,
                name: "班课管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/c26bea38c7d04f4d41c67162150a9f19.png"
            },
            {
                id: 2,
                name: "日程管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/8f65fab02b28179c32a40fd62fa813e3.png"
            },
            {
                id: 3,
                name: "在线商城",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a01688f8aeb96e62981785863239b71c.png"
            },
            {
                id: 4,
                name: "集赞活动",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/fb5d115bbcd2ff93c658da4386a141fe.png"
            },
            {
                id: 5,
                name: "学员管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/e5e393d5d287156f477136dc19ed36ec.png"
            },
            {
                id: 6,
                name: "会员储值",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/fb29ea6b503c870360ea4e92e5023d6d.png"
            },
            {
                id: 7,
                name: "老师管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/52d27aff26f6c2e898e88d35b81f3c0a.png"
            },
            {
                id: 8,
                name: "财务结算",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a0724a925d6f0d7d57a2acb2ea0d84d7.png"
            }
        ]
    },
    {
        id: 3,
        name: "智慧零售",
        title: "智慧零售解决方案",
        content: "智慧零售解决方案，通过直营/加盟门店数字化、渠道数字化、营销数字化、会员数字化等帮助企业搭建数字化基建，实施全链路、全场景的涨粉丝、涨流量、涨销量的营销活动，提升转化率、裂变率、到店率；助力企业快速搭建社交新零售平台。",
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/eb1009f89135443eba7767835397093e.png",
        icon_list: [
            {
                id: 1,
                name: "在线商城",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a01688f8aeb96e62981785863239b71c.png"
            },
            {
                id: 2,
                name: "进销存",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/2b245148175d6ccef508764dad70018d.png"
            },
            {
                id: 3,
                name: "门店管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a053a7a4fc58cde40d45c044908b3e6e.png"
            },
            {
                id: 4,
                name: "营销工具",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/8e24af899f6f5e2f5240268a169d46ed.png"
            },
            {
                id: 5,
                name: "会员管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/af682ecc2c27bd15ae7b795c6d283e07.png"
            },
            {
                id: 6,
                name: "会员储值",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/fb29ea6b503c870360ea4e92e5023d6d.png"
            },
            {
                id: 7,
                name: "数据看板",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/63d06f092f3da7f9fdc912c97419a7a0.png"
            },
            {
                id: 8,
                name: "分销裂变",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a964ba6854c3be3cd4a4231feebbc7d3.png"
            }
        ]
    },
    {
        id: 4,
        name: "智慧娱乐",
        title: "智慧娱乐解决方案",
        content: "智慧娱乐解决方案，KTV、酒吧连锁一体化经营管理解决方案。适合KTV、酒吧、棋牌室连锁加盟等多种娱乐经营形态。在线预约、在线点餐、内容营销，助力销量和客户双增长；实现线上、线下一体化经营，门店高效管理。帮助商家提升营收业绩、提高管理水平、降低经营成本。",
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/cbf2ed7bb2c24c75b2f5a730b2e5a884.png",
        icon_list: [
            {
                id: 1,
                name: "在线商城",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a01688f8aeb96e62981785863239b71c.png"
            },
            {
                id: 2,
                name: "在线预约",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/3815570fec70e7a9e9301bed75f20876.png"
            },
            {
                id: 3,
                name: "扫码点餐",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/7837ed5aa35b685115b7ee843aac7c99.png"
            },
            {
                id: 4,
                name: "门店管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a053a7a4fc58cde40d45c044908b3e6e.png"
            },
            {
                id: 5,
                name: "营销工具",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/8e24af899f6f5e2f5240268a169d46ed.png"
            },
            {
                id: 6,
                name: "会员储值",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/fb29ea6b503c870360ea4e92e5023d6d.png"
            },
            {
                id: 7,
                name: "数据看板",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/63d06f092f3da7f9fdc912c97419a7a0.png"
            },
            {
                id: 8,
                name: "财务结算",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/a0724a925d6f0d7d57a2acb2ea0d84d7.png"
            }
        ]
    },
    {
        id: 5,
        mainId:4,
        name: "视频号",
        title: "视频号解决方案",
        content: "视频号直播+小程序+公众号+社群裂变营销解决方案，3分钟搭建自己的直播间，帮助商家多平台同步卖货，裂变百万粉丝，高效引流变现，让12亿微信用户发现你的店，抢占视频号流量阵地，引爆微信生态全链路私域增量。",
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/828ce7a4c65d4064c6ef074586a971e3.png",
        icon_list: [
            {
                id: 1,
                name: "边看边买",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/879bfd43c87b7d209f2c44f769365f25.png"
            },
            {
                id: 2,
                name: "短视频分享",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/10868ac23e4fed05ab252b1b9e3d24b6.png"
            },
            {
                id: 3,
                name: "社群分享",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/784dc8922c38c2ea64449667074ab88b.png"
            },
            {
                id: 4,
                name: "小程序引流",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/bb0e6c7f8f76bcef99c9d0fe58b9a356.png"
            },
            {
                id: 5,
                name: "营销工具",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/8e24af899f6f5e2f5240268a169d46ed.png"
            },
            {
                id: 6,
                name: "会员管理",
                icon_img: "https://img.cxkoo.com/chengxuan/1/2022-08-02/af682ecc2c27bd15ae7b795c6d283e07.png"
            },
            {
                id: 7,
                name: "",
                icon_img: ""
            },
            {
                id: 8,
                name: "",
                icon_img: ""
            }
        ]
    },

]
const BASE_URL= 'http://mp.cxshope.com'

export default class Solution extends Component {
    state = {
        current: panes[0]
    }

    handleChageMenu = index => {

        return () => {

            let current = this.state.current
            current = panes[index]
            this.setState({
                current
            })
        }
    }

    handleGoPage = () => {

        let current = this.state.current
        if (!current.mainId) {
            return
        }

        let updateMainIdFun = this.props.updateMainIdFun
        if (typeof updateMainIdFun === 'function') {
            updateMainIdFun(current.mainId)
        }
    }

    render() {
        const { current } = this.state
        return (
            <div className='solution-box' style={{ backgroundImage: `url(${current.bg_img})` }}>
                <div className="wrapper solution">
                    <div className='fs-26 tac fw-600'>深入细分行业，提供更加垂直的行业解决方案</div>
                    <div className='fs-14 tac mgt10 mgb20'>场馆预订、教育培训、电商零售、休闲娱乐，澄瑄都有完整解决方案</div>
                    <div className="flex">
                        <div className="tab-menus">
                            {
                                panes.map((item, index) => {
                                    return (
                                        <div onMouseEnter={this.handleChageMenu(index)} className={`tab-menu ${item.id == current.id ? 'active' : ''}`} key={item.id}>{item.name}</div>
                                    )
                                })
                            }
                        </div>
                        <div className="solution-container">
                            <div className="flex justify-between align-center">
                                <div className="fs-20">{current.title || ""}</div>
                                <div className='flex'>
                                    <div className="btn-dufault" onClick={this.handleGoPage}>查看详情</div>
                                    <div className="btn-primary"> <a href={BASE_URL} target="_blank" >免费试用</a></div>
                                </div>
                            </div>
                            <div className='lh1-5 fs14 mgt20'>{current.content || ""}</div>
                            <div className="icon-groups">
                                {
                                    current.icon_list.map(val => {
                                        return (
                                            <div className='icon-item' key={val.id}>
                                                {val.icon_img ? <img src={val.icon_img} alt="" /> : null}
                                                <p className='mgt10'>{val.name || ''}</p>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
