import React, { Component } from 'react'
import ModalWeCom from '@/components/ModalWeCom';
import './index.scss';
const apiLists=[
    {
        id:1,
        icon:"https://img.cxkoo.com/chengxuan/1/2022-12-09/4fca5b46faa10bd1d08fd49c73162c10.png",
        title:"商品API",
        text:"商品信息、库存管理"
    },
    {
        id:2,
        icon:"https://img.cxkoo.com/chengxuan/1/2022-12-09/00bc3a0208d47330bb3639d273e55abf.png",
        title:"客户API",
        text:"客户列表、客户详情、互动轨迹"
    },
    {
        id:3,
        icon:"https://img.cxkoo.com/chengxuan/1/2022-12-09/7cb20285dbadce7dee00a75c72e17ffb.png",
        title:"客户洞察API",
        text:"客户标签、客户自定义属性管理"
    },
    {
        id:4,
        icon:"https://img.cxkoo.com/chengxuan/1/2022-12-09/20456e45b8364c141180d95579c889f5.png",
        title:"订单API",
        text:"订单数据与状态同步"
    },
    {
        id:5,
        icon:"https://img.cxkoo.com/chengxuan/1/2022-12-09/faa9fcea156da6ba52dfd385068dde35.png",
        title:"营销API",
        text:"多钟营销工具"
    },
    {
        id:6,
        icon:"https://img.cxkoo.com/chengxuan/1/2022-12-09/d327242153b19015c99c338d43af256c.png",
        title:"更多能力",
        text:"提交需求，快速对接"
    }
]

export default class Open extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
    }

    handleSeeWeCom=()=>{
        this.ModalWeCom.show()
    }

    render() {
        return (
            <>
                <div className="open-banner text-white">
                    <p className='fs-48 text-white' >澄瑄开放平台</p>
                    <div className="mgt20 fs-20">打破数据孤岛，实现企业智能化数据建设，加速企业数字化转型</div>
                    <div className='mgt60'>
                        <button className="btn" onClick={this.handleSeeWeCom}>立即咨询</button>
                    </div>
                </div>
                <div className="fs-40 tac fw-500 pdt80">服务能力</div>
                <div className="wrapper open-serve tac">
                    <div className="serve-item">
                        <i className="icon1"></i>
                        <p className='fs-18 mgt20'>数据接入</p>
                        <p className='fs-14 mgt20 tal'>支持企业自有数据通过多种方式灵活接入，形成对私域会员的全域洞察</p>
                    </div>
                    <div className="serve-item">
                        <i className="icon2"></i>
                        <p className='fs-18 mgt20'>业务接入</p>
                        <p className='fs-14 mgt20 tal'>基于业务扩展点，支持企业自有业务模块化嵌入至澄瑄软件系统，打通私域会员的完整业务流</p>
                    </div>
                    <div className="serve-item ">
                        <i className="icon3"></i>
                        <p className='fs-18 mgt20'>开放API</p>
                        <p className='fs-14 mgt20 tal'>丰富的API接口，企业可以基于API将澄瑄软件系统与内部已有信息系统进行有效整合，实现全面数字化升级</p>
                    </div>
                    <div className="serve-item ">
                        <i className="icon4"></i>
                        <p className='fs-18 mgt20'>解决方案咨询</p>
                        <p className='fs-14 mgt20 tal'>客户成功导向，基于丰富的行业经验结合企业特征深度诊断，为企业量身定制完整解决方案</p>
                    </div>
                </div>
                <div className="open-api">
                    <div className="fs-40 tac fw-500 pdt80">开放API</div>
                    <div className="api-wrap wrapper">
                        {
                            apiLists.map(item=>(
                                <div className="api-col" key={item.id}>
                                    <div className="left">
                                        <i className="icon" style={{backgroundImage: `url('${item.icon}')` }} ></i>
                                    </div>
                                    <div>
                                        <p className="fs-24 fw-500">{item.title}</p>
                                        <p className="fs-14 mgt10">{item.text}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="process wrapper">
                    <div className="fs-40 tac fw-500">接入流程</div>
                    <div className="row mgt50 tac">
                        <div className="col">
                            <i className="icon1"></i>
                            <p className="fs-18 fw-500 mgt20">开通澄瑄旗下系统产品</p>
                            <p className="fs-14 mgt10">仅针对付费版企业开放</p>
                        </div>
                        <div className="you-icon"></div>
                        <div className="col">
                            <i className="icon2"></i>
                            <p className="fs-18 fw-500 mgt20">开发者认证</p>
                            <p className="fs-14 mgt10">提交开发者资质信息</p>
                        </div>
                        <div className="you-icon"></div>
                        <div className="col">
                            <i className="icon3"></i>
                            <p className="fs-18 fw-500 mgt20">申请Key</p>
                            <p className="fs-14 mgt10">获取专属调用秘钥</p>
                        </div>
                        <div className="you-icon"></div>
                        <div className="col">
                            <i className="icon4"></i>
                            <p className="fs-18 fw-500 mgt20">集成产品与服务</p>
                            <p className="fs-14 mgt10">调用API进行产品集成</p>
                        </div>
                    </div>
                    <div className="mgt50 tac">
                        <button className='btn' onClick={this.handleSeeWeCom}>立即咨询</button>
                    </div>
                </div>

                <ModalWeCom onRef={e=>this.ModalWeCom=e}></ModalWeCom>
            </>
        )
    }
}
