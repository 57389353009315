import React, { Component } from 'react'
import './index.scss'

export default class About extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
    }
    
    render() {
        return (
            <>
                <div className="about-head">
                    <div className="wrapper text-white tac ">
                        <p className="fs-48 ">让商业变得更有价值</p>
                        <p className='fs-20 mgt20'>助力商家经营可持续增长</p>
                    </div>
                </div>
                <div className="wrapper pdt80 pdb60 flex">
                    <div className='fs-18 flex1'>
                        <p className="fs-30 fw-600">澄瑄简介</p>
                        <p className='lh2 indent2 mgt30'>我们是一家以客户为中心，依托创新的意识、创建集成的能力，为用户设计完整的智能技术解决方案；提供优质的系统支持服务，助力中小企业进行互联网+转型与升级的公司。
                        </p>
                        <p className='lh2 indent2 mgt20'>公司成立于2020年，致力于为企业提供最前沿的移动互联网思维，公司技术研发人员均具有多年的互联网产品设计、开发、从业经验，是一家专注于小程序商业级应用系统研发及运营的技术企业。为行业门店打造线上、线下一体化移动营销解决方案，从智能化店务管理、全渠道营销拓客、全方位数据分析、数字化运营管理等多个板块，全面助力服务型门店管理升级。</p>
                    </div>
                    <div className='company-img'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-04/9c862de1e5ab425479059c3d5b3d6166.png" alt="" />
                    </div>
                </div>

                <div className="about-footer">
                    <div className="wrapper fs-18 text-white">
                        <p className="fs-30 fw-600 text-c000">公司定位</p>
                        <p className='mgt30'>专注线下门店业务，独立运营的技术创新团队，作为小程序生态服务商，为不同行业实体店提供小程序拓客方案。</p>
                        <div className="mgt30">为客户提供安全可信赖的产品、解决方案与服务；并服务于商家，实现全域营销、私域沉淀。</div>
                        <p className="fs-30 fw-600 text-c000 mgt50">我们的优势</p>
                        <div className="mgt30">专业化团队——我们拥有经验丰富的技术及运营团队</div>
                        <div className="mgt30">精细化服务——协助每一个商家搭建属于自己的私域商城</div>
                        <div className="mgt30">为个性化定制——根据不同行业有针对性的定制获客营销方案</div>
                    </div>
                </div>

            </>
        )
    }
}
