import React, { Component } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper'
import ModalWeCom from '../ModalWeCom';
import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss';

const banner_list = [
    {
        id: 1,
        title: "智慧零售解决方案",
        title_color: '#fff',
        color: ['#C746FE', '#fff'],
        summary: ['沉淀私域流量，实现精准营销', ''],
        summary_color: '#fff',
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-03/85171f239869463a4078d361246bb3db.png",
        mainId: null
    },
    {
        id: 2,
        title: "智慧教培解决方案",
        title_color: '#fff',
        color: ['#fff', '#2FA4FF'],
        summary: ['专为教培培训设计', '招生营销+CRM，智能教务管理解决方案'],
        summary_color: '#fff',
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-08-03/6daf7aa5cd8d2ab1fcfb9abb43975b09.png",
        mainId: 3,
    },
    {
        id: 3,
        title: "视频号解决方案",
        title_color: '#484848',
        color: ['#1751B7', '#fff'],
        summary: ['快速抢占流量新阵地', '助您打造流量价值最大化'],
        summary_color: '#818181',
        bg_img: "https://img.cxkoo.com/chengxuan/1/2022-12-07/0682eaa9fc74c98f513638ea502c0429.png",
        mainId: 4
    }
]

export default class Banner extends Component {
    handleGoPage = (id) => {
        return () => {

            if (this.props.no_swipe) {
                return  this.ModalWeCom.show()
            }

            if (!id) {
                return
            }
            let updateMainIdFun = this.props.updateMainIdFun
            if (typeof updateMainIdFun === 'function') {
                updateMainIdFun(id)
            }
        }
    }

    render() {
        let pagination = { bulletClass: 'banner-pagination', bulletActiveClass: 'banner-pagination-active', clickable: true },
            autoplay = { delay: 5000, disableOnInteraction: false },
            allowTouchMove = true,
            initialSlide = 0;


        if (this.props.no_swipe) {
            pagination = false
            autoplay = false
            allowTouchMove = false
        }

        if (this.props.current) {
            initialSlide = this.props.current
        }


        return (
            <>
                <div className='banner-comp'>
                    <Swiper
                        initialSlide={initialSlide}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        pagination={pagination}
                        modules={[Pagination, Autoplay]}
                        autoplay={autoplay}
                        allowTouchMove={allowTouchMove}
                    >

                        {
                            banner_list.map(val => {
                                return (
                                    <SwiperSlide key={val.id}>
                                        <div className="banner-item" style={{ "backgroundImage": `url(${val.bg_img})` }}>
                                            <div className="wrapper text-white">
                                                <p className='fs-48' style={{ 'color': val.title_color }} >{val.title}</p>
                                                <p className='mgt20 fs-20' style={{ 'color': val.summary_color }} >{val.summary[0]}</p>
                                                <p className='mgt10 fs-20' style={{ 'color': val.summary_color }} >{val.summary[1]}</p>
                                                <a onClick={this.handleGoPage(val.mainId)}>
                                                    <div className="moer-btn" style={{ 'backgroundColor': val.color[0], 'color': val.color[1] }}>{this.props.no_swipe ? '立即咨询' : '了解更多'}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>
                </div>
                {this.props.no_swipe? <ModalWeCom onRef={e=>this.ModalWeCom=e} />:null}
            </>
        )
    }
}
