import React, { Component } from 'react'
import { Route, Routes, Navigate, useNavigate ,useLocation} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Home from './views/home'
import About from './views/about';
import Educate from './views/educate';
import Channels from './views/channels';
import Open from './views/open';
import Cooperation from './views/cooperation';
import './App.scss';

const routeData = [
  {
    id: 1,
    path: '/index'
  },
  {
    id: 2,
    path: '/about'
  },
  {
    id: 3,
    path: '/educate'
  },
  {
    id: 4,
    path: '/channels'
  },
  {
    id: 5,
    path: '/open'
  }
]

export default function App() {
  const navigate = useNavigate()

  const updateMainIdFun = id => {
    if (!id) {
      return
    }
    let current = routeData.find(val => val.id == id);
    navigate(current.path)
  }


  const location=useLocation();


  return (
    <div className="app">
      <Header updateMainIdFun={updateMainIdFun}></Header>
      {/* {this.renderMain()} */}

      <Routes>
        <Route element={<Home updateMainIdFun={updateMainIdFun} />} exact path='/index' ></Route>
        <Route element={<About />} exact path='/about' ></Route>
        <Route element={<Educate />} exact path='/educate' ></Route>
        <Route element={<Channels />} exact path='/channels' ></Route>
        <Route element={<Open />} exact path='/open' ></Route>
        <Route element={<Cooperation />} exact path='/cooperation' ></Route>
        <Route element={<Navigate to='/index' replace={true} />} path='*' ></Route>
      </Routes>
      <Footer  location={location} ></Footer>
      <Sidebar></Sidebar>
    </div>
  )
}


