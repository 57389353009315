import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import './index.scss';

const BASE_URL= 'http://mp.cxshope.com'

export default class Header extends Component {

  handleGoPage = id => {
    return () => {
      let updateMainIdFun = this.props.updateMainIdFun
      if (typeof updateMainIdFun === 'function') {
        updateMainIdFun(id)
      }
    }
  }

  render() {
    return (
      <header className='header-box'>
        <div className="wrapper header">
          <div className="logo">
            <img src="https://img.cxkoo.com/chengxuan/1/2022-12-09/c22d388615c2eadd612f5e17aef8c563.png" alt="" />
          </div>
          <div className="nav">
            <div className="nav-list">
              <div className="nav-item text_link"><Link to={'/index'} >首页</Link></div>
              <div className="nav-item ">
                <div><a >产品和服务 <span className='icon-xia'></span></a></div>
                <div className="subnav_container lh1-5">
                  <div className='subnav-1'>
                    <div className="title">产品</div>
                    <div>
                      <div className='flex mgt30'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-02/699c6ab804c1583c26fbb7994335ebc8.png" className='text_link' alt="" onClick={this.handleGoPage(3)} />
                        <div className='mgl20'>
                          <p className='fs-16 text_link' ><Link to={'/educate'} target={'_blank'} >教培</Link></p>
                          <p className='fs-14 text-c646566'>全场景营销，助力招生经营解决方案</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-02/dc005eebaefceec00c532b1118cd7761.png" alt="" />
                        <div className='mgl20'>
                          <p className='fs-16'>新零售</p>
                          <p className='fs-14 text-c646566'>全渠道全场景私域开店解决方案</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-02/bbb5e5f968461e29ab35542691e00759.png" alt="" />
                        <div className='mgl20'>
                          <p className='fs-16'>娱乐</p>
                          <p className='fs-14 text-c646566'>KTV、酒吧连锁一体化解决方案</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='subnav-2'>
                    <div className="title">服务</div>
                    <div>
                      <div className='flex mgt30'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-02/0edaf46a8bdcdb8512a28cfa0829cd04.png" alt="" />
                        <div className='mgl20'>
                          <p className='fs-16'>广告投放</p>
                          <p className='fs-14 text-c646566'>投放海量优质流量</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-02/b48a8592607512504fe5cf6b5cdc2210.png" alt="" />
                        <div className='mgl20'>
                          <p className='fs-16'>私域运营</p>
                          <p className='fs-14 text-c646566'>私域全方位运营服务</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-08-02/48789d9da0f4ce8e57321407bf286e67.png" alt="" />
                        <div className='mgl20'>
                          <p className='fs-16'>个性开发</p>
                          <p className='fs-14 text-c646566'>定制开发及系统集成</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-item ">
                <div> <a >解决方案 <span className='icon-xia'></span></a></div>
                <div className="subnav_container lh1-5">
                  <div className='subnav-1'>
                    <div className="title">社交电商解决方案</div>
                    <div>
                      <div className='flex mgt30'>
                        <div >
                          <p className='fs-16'><Link to={'/channels'} target={'_blank'} >视频号运营解决方案</Link></p>
                          <p className='fs-14 text-c646566'>抢占视频号创业先机</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <div >
                          <p className='fs-16'>小程序解决方案</p>
                          <p className='fs-14 text-c646566'>在小程序转私域流量</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <div >
                          <p className='fs-16'>门店智慧零售解决方案</p>
                          <p className='fs-14 text-c646566'>零售业智慧门店全域增长方案</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='subnav-2'>
                    <div className="title">行业解决方案</div>
                    <div>
                      <div className='flex mgt30'>

                        <div >
                          <p className='fs-16 text_link'><Link to={'/educate'} target={'_blank'} >智慧教培</Link></p>
                          <p className='fs-14 text-c646566'>全场景营销，助力招生经营解决方案</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <div >
                          <p className='fs-16'>数字场馆</p>
                          <p className='fs-14 text-c646566'>体育场馆综合解决方案</p>
                        </div>
                      </div>
                      <div className='flex mgt30'>
                        <div >
                          <p className='fs-16'>智慧娱乐</p>
                          <p className='fs-14 text-c646566'>KTV、酒吧连锁一体化解决方案</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-item text_link"><Link to={'/open'}  >开放平台</Link></div>
              <div className="nav-item text_link"><Link to={'/cooperation'} >渠道合作</Link></div>
              <div className="nav-item text_link"><Link to={'/about'}  >关于我们</Link></div>
            </div>
          </div>
          <div className="login">
            <div ><a href={BASE_URL} target="_blank" >登录</a></div>
            <div className='login-btn' ><a href={BASE_URL} target="_blank" >免费试用</a></div>
          </div>
        </div>
      </header>
    )
  }
}
