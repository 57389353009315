import React, { Component } from 'react'
import ModalWeCom from '@/components/ModalWeCom';
import './index.scss';

const forte_list = [
    {
        id: 1,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-12-29/1988e8813339b864b711427ec49ebc0b.png",
        title: "高回报率",
        content: "丰厚销售返利、新客合作、老客续费、交付服务等"
    },
    {
        id: 2,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-12-29/cd2f6f4e397aa6cbc9caa2c9f4a73614.png",
        title: "培训支持",
        content: "定期提供针对产品、市场、营销等全方面的培训及全方面产品功能讲解"
    },
    {
        id: 3,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-12-29/90dcb190df1f13a5ec4800adcc35c401.png",
        title: "定点扶持",
        content: "重点代理商深度持续扶持，助力业务快速启动、飞速发展"
    },
    {
        id: 4,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-12-29/9aae8ae207ac06a43f6e65edc71dd5a0.png",
        title: "市场支持",
        content: "总部市场行为，开单激励、物料支持"
    },
    {
        id: 5,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-12-29/9a5c02cbd40fb9273e9dc0ae4ebdffc2.png",
        title: "售后支持",
        content: "提供专属客服，帮助解决产品、销售、技术等市场运营问题"
    },
    {
        id: 6,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-12-29/fb87afeba79708946ba9b00b765b2518.png",
        title: "产研支持",
        content: "快速响应企业个性化需求，助力大客赢单"
    },
]

export default class Cooperation extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleSeeWeCom = () => {
        this.ModalWeCom.show()
    }

    render() {
        return (
            <>
                <div className="cooperation-banner text-c262e90">
                    <p className='fs-48' >代理商招募计划</p>
                    <div className="mgt20 fs-20">聚力同心，成为合作伙伴，共生共赢</div>
                    <div className='mgt60'>
                        <button className="btn" onClick={this.handleSeeWeCom}>立即咨询</button>
                    </div>
                </div>

                <div className="fs-40 tac fw-500 pdt80">成为澄瑄代理商，门槛低 、 收益高、返利快</div>

                <div className="wrapper forte-wrap">

                    {
                        forte_list.map(item => (
                            <div className="forte-item" key={item.id} >
                                <div className="top">
                                    <img src={item.img_url} alt="" />
                                </div>
                                <div className="bottom">
                                    <p className="fs-18 fw-600 text-c000">{item.title}</p>
                                    <p className="fs-14 text-c646566 mgt20">{item.content}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="process  bg-f5 mgt60">
                    <div className="wrapper">
                        <div className="fs-40 tac fw-500">接入流程</div>
                        <div className="row mgt50 tac">
                            <div className="col">
                                <i className="icon1"></i>
                                <p className="fs-18 fw-500 mgt20">提交申请</p>
                                <p className="fs-14 mgt10">提交资料申请成为合作伙伴</p>
                            </div>
                            <div className="you-icon"></div>
                            <div className="col">
                                <i className="icon2"></i>
                                <p className="fs-18 fw-500 mgt20">商务洽谈</p>
                                <p className="fs-14 mgt10">确认合作形式及方案</p>
                            </div>
                            <div className="you-icon"></div>
                            <div className="col">
                                <i className="icon3"></i>
                                <p className="fs-18 fw-500 mgt20">签订协议</p>
                                <p className="fs-14 mgt10">签订服务及保密协议</p>
                            </div>
                            <div className="you-icon"></div>
                            <div className="col">
                                <i className="icon4"></i>
                                <p className="fs-18 fw-500 mgt20">业务开展</p>
                                <p className="fs-14 mgt10">业务培训支持</p>
                            </div>
                        </div>
                        <div className="mgt50 tac">
                            <button className='btn' onClick={this.handleSeeWeCom}>立即咨询</button>
                        </div>
                    </div>
                </div>

                <ModalWeCom onRef={e => this.ModalWeCom = e}></ModalWeCom>
            </>
        )
    }
}
