import React, { Component } from 'react'
import Banner from '@/components/Banner'
import Solution from './components/Solution'
import './index.scss'

const product_list = [
    {
        id: 1,
        mainId:3,
        title: "教培",
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/8fdaacc81de89ba5b676b7b44b663b94.png",
        subtitle: "全场景营销，助力招生经营解决方案",
        content: "数字化技术解决线上线下招生运营、管理难题，提供多校区经营场景，提升管理、协作效率"
    },
    {
        id: 2,
        title: "新零售",
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/89f79c9d39243456cb6e38dcd09bd9e7.png",
        subtitle: "全渠道全场景私域开店解决方案",
        content: "快速构建满足线上商品销售、推广营销等基础需求的私域商城，实现快速裂变分销、高效促活转化"
    },
    {
        id: 3,
        title: "娱乐",
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/1bd7e649789d5a30111418a490030c3f.png",
        subtitle: "KTV、酒吧连锁一体化解决方案",
        content: "在线预约、内容营销，助力销量和客户双增长；实现线上、线下一体化经营，门店高效管理"
    }
]

const service_list = [
    {
        id: 1,
        title: "设计服务",
        content: ['整店装修设计', '首页定制设计', '商品详情页设计', '线下物料设计', '商品拍摄精修']
    },
    {
        id: 2,
        title: "私域运营",
        content: ['全店运营服务', '内容运营', '社群运营', '新零售咨询带教', '企业微信运营', '运营实施方案', '营销活动方案']
    },
    {
        id: 3,
        title: "推广运营",
        content: ['小红书内容推广', '抖音短视频推广', '朋友圈推广', '媒介合作', '异业联盟']
    },
    {
        id: 4,
        title: "自媒体运营",
        content: ['达人带货直播', '抖音短视频拍摄', '探店视频拍摄', '视频号直播培训']
    },
    {
        id: 5,
        title: "个性定制开发",
        content: ['数据打通', '独立部署', '技术实施', '定制开发']
    }
]

const brand_list = [
    {
        id: 1,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/28be577fe6f925e344d8033cdd86d009.png",
        logo: "https://img.cxkoo.com/chengxuan/1/2022-08-01/1aa0a0dfdf709c92c10400ac37f0435a.png",
        title: "INLOVE KTV",
        content: "“KTV因受疫情影响导致业绩下滑，复工后通过【限时折扣】功能，单日销量突破千万。”",
        type: "休闲娱乐"
    },
    {
        id: 2,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/d7310c66d6de02588af69be7e84a8b26.png",
        logo: "https://img.cxkoo.com/chengxuan/1/2022-08-01/4483551825d5eb1573731f598e3ee4a5.png",
        title: "POINT CHENG",
        content: "“中国独立设计师程子创立的原创服饰品牌，利用社群及朋友圈推广，粉丝数达到1000+。”",
        type: "服饰"
    },
    {
        id: 3,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/5458a6d792d2f5d463406ab927f4eb2d.png",
        logo: "https://img.cxkoo.com/chengxuan/1/2022-08-01/e6b17f6bb29a0563baeef646e3d2010f.png",
        title: "TCL",
        content: "“借助【集赞活动】，快速裂变、流量聚合。活动预热2天，参与客户达1万+。”",
        type: "电器"
    },
    {
        id: 4,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/cbc9154867dfee16ed748ddc26ab068d.png",
        logo: "https://img.cxkoo.com/chengxuan/1/2022-08-01/a954205ede437f6dea1751c29820de01.png",
        title: "浩炫全案设计",
        content: "“浩炫与澄瑄深入合作，进行是集设计、施工、软装设计进行“数字+”转型，打造品牌增长高地。”",
        type: "家居装饰"
    },
    {
        id: 5,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/d132c6a58d096b31d3803a595cc7b780.png",
        logo: "https://img.cxkoo.com/chengxuan/1/2022-08-01/456746155b3ce244cb679340e88fd387.png",
        title: "新青牛篮球公园",
        content: "“通过【在线订场】【智能教务管理平台】及【班课管理】完成场地及教培的一体化智能管理。”",
        type: "体育教培"
    },
    {
        id: 6,
        img_url: "https://img.cxkoo.com/chengxuan/1/2022-08-01/4ae1a220cf1c12def2aa4b0db19ae7de.png",
        logo: "https://img.cxkoo.com/chengxuan/1/2022-08-01/7dee0fd59be2b4db08e48949bec38904.png",
        title: "大灌篮体育",
        content: "“通过在线订场、会员管理和营销活动等丰富功能，降低人工成本，打通线上线下一体化。”",
        type: "体育教培"
    },

]

const information_list = [
    {
        id: 1,
        title: "安全稳定",
        content: "依靠技术的多年积累，利用云部署、云防火墙等全方位监控，为商家的业务全力保驾护航"
    },
    {
        id: 2,
        title: "数据安全",
        content: "自主研发可靠多级加密秘钥体系，实现组户级灵活数据加密和数据脱敏，保护您和用户的数据安全"
    },
    {
        id: 3,
        title: "安全防护",
        content: "借助全局DDos防御系统和澄瑄自研防御系统相结合，防御大流量攻击，快速精准清洗和防护，保障商家的业务安全"
    },
    {
        id: 4,
        title: "实时备份",
        content: "数据库多地实时备份，同时也进行云备份。达到数据备份无死角，保障商家的数据安全"
    }
]

export default class Home extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
    }

    handleGoPage = (id) => {
        return () => {
            
            if(!id){
                return
            }
            let updateMainIdFun = this.props.updateMainIdFun
            if (typeof updateMainIdFun === 'function') {
                updateMainIdFun(id)
            }
        }
    }

    render() {
        return (
            <>
                <Banner updateMainIdFun = {this.props.updateMainIdFun} ></Banner>

                <div className="wrapper product">
                    <div className="block-title">了解我们的SaaS产品</div>
                    <div className="block-subtitle">全渠道全场景的SaaS产品，提供商家解决</div>
                    <div className="product-column">
                        {
                            product_list.map(item => {
                                return (
                                    <div className="product-item" key={item.id}>
                                        <div className="top">
                                            <img src={item.img_url} alt="" />
                                            <div className="title">{item.title}</div>
                                        </div>
                                        <div className="bottom">
                                            <div className="subtitle">{item.subtitle}</div>
                                            <div className="content">{item.content}</div>
                                        </div>
                                        <div className="btns">
                                            <a href=""><div className="btn-primary">免费试用</div></a>
                                            <a onClick={this.handleGoPage(item.mainId)}> <div className="btn-default">查看详情</div></a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="bg-f5">
                    <div className="wrapper service">
                        <div className="block-title">完善的服务能力</div>
                        <div className="block-subtitle">澄瑄提供专业的服务，助你快速上手，推动新店快速上线</div>
                        <div className="service-column">
                            {
                                service_list.map(item => {
                                    return (
                                        <div className="service-item" key={item.id}>
                                            <div className="top">
                                                <span className={'icon-' + item.id}></span>
                                                <span className='mgl10'>{item.title}</span>
                                            </div>
                                            <div className="bottom">
                                                {
                                                    item.content.map((val, index) => {
                                                        return (
                                                            <div className="row" key={index}>
                                                                <span className='icon-gougou'></span>
                                                                <span>{val}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <Solution updateMainIdFun = {this.props.updateMainIdFun}></Solution>

                <div className="wrapper brand">
                    <div className="block-title">多家合作品牌的一致认可</div>
                    <div className="more">查看更多案例 <span className='icon-you'></span></div>
                    <div className="brand-column">
                        {
                            brand_list.map(item => {
                                return (
                                    <div className="brand-item" key={item.id}>
                                        <div className="top">
                                            <img src={item.img_url} alt="" />
                                        </div>
                                        <div className="bottom">
                                            <div className="logo">
                                                <img src={item.logo} alt="" />
                                            </div>
                                            <div className="title">{item.title}</div>
                                            <div className="content">{item.content}</div>
                                            <div className="mgt20 content">主营：{item.type}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="information-box">
                    <div className="wrapper information text-white">
                        <div className="fs-26 tac fw-600 ">我们全力保障您的数据安全</div>
                        <div className="information-column">
                            {
                                information_list.map(val => {
                                    return (
                                        <div className="information-item tac" key={val.id}>
                                            <div className={'icon-' + val.id}></div>
                                            <div className="fs-16 mgt20 mgb20">{val.title}</div>
                                            <div className="fs-14 tal lh2 text-c999">{val.content}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
