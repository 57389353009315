import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.scss';

const BASE_URL = 'http://mp.cxshope.com';
const hide_list = ['/cooperation'];

export default class Footer extends Component {


    render() {

        let location = this.props.location || {}

        return (
            <>
                {
                    hide_list.includes(location.pathname) ? null :
                        <div className="bg-5d4cfe pdt80 pdb80">
                            <div className="wrapper">
                                <div className="fs-26 tac fw-600 text-white">现在注册，享新用户大礼包一份</div>
                                <div className="gift tac mgt30">
                                    <a href={BASE_URL} target="_blank" >
                                        <button>立即注册</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                }
                <div className='wrapper pdt80 pdb80 footer'>
                    <div className="flex fs-14 text-c646566" >
                        <div className='mgr120 pdl30'>
                            <div className='fs-16 fw-600 text-c000'>关于我们</div>
                            <div className='mgt20'>
                                <div>
                                    <p><Link to={'/about'}>澄瑄介绍</Link> </p>
                                </div>
                            </div>
                        </div>

                        <div className='mgr120'>
                            <div className='fs-16 fw-600 text-c000'>解决方案</div>
                            <div className='flex mgt20' >
                                <div className='mgr30'>
                                    <p><Link to={'/educate'}  >澄瑄教培</Link></p>
                                    <p className='mgt20'>智能场馆</p>
                                    <p className='mgt20'><Link to={'/channels'} >视频号运营</Link></p>
                                </div>
                                <div>
                                    <p>澄瑄新零售</p>
                                    <p className='mgt20'>休闲娱乐</p>
                                    <p className='mgt20'>个性定制服务</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='fs-16 fw-600 text-c000'>联系我们</div>
                            <div className='mgt20'>
                                <div>
                                    <p>开店咨询</p>
                                    <p className='mgt20 fw-600'>130 6472 1693</p>
                                </div>
                            </div>
                        </div>

                        <div className='mgl-auto pdr60'>
                            <div className='fs-16 fw-600 text-c000'>关注澄瑄</div>
                            <div className='mgt20'>
                                <div>
                                    <p className='text-c000'>关注公众号，微博了解更多咨询</p>
                                    <div className='mgt20'>
                                        <img src="https://img.cxkoo.com/chengxuan/1/2022-12-12/7bf310ffce540678f945ea7615fadfff.png" alt="" className="qr_code" />
                                        <img src="https://img.cxkoo.com/chengxuan/1/2022-12-12/786cb2f0d84beaad569c94336083d296.png" alt="" className="qr_code  mgl20" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright fs-14">
                        <p className='mgt20'>
                            <span>Copyright © 2020-2022 cxshope.com All Rights Reserved</span>
                            <span className='mgl30'>杭州澄瑄网络科技有限公司版权所有</span>
                        </p>
                        <p className='mgt20'>
                            <span>咨询热线：130 6472 1693</span>
                            <span className='mgl30'>邮箱：xcwl_0128@sina.com</span>
                            <span className='mgl30'>地址：杭州市拱墅区东方茂商业中心T1-13-C001</span>
                        </p>
                        <p className='mgt20'>
                            <a target={'_blank'} href="https://beian.miit.gov.cn">工业和信息化部 浙ICP备2021021901号-2</a>
                            <a target={'_blank'} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010502007078" className='mgl30'><span className='copyright-icon'></span> 浙公安网安备33010502007078</a>
                        </p>
                    </div>
                </div>
            </>
        )
    }
}
