import React, { Component } from 'react'
import './index.scss'

export default class ModalWeCom extends Component {
    state = {
        show: false
    }

    componentDidMount(){
        this.props.onRef(this)
    }

    show = () => {
        this.setState({
            show:true
        })
    }

    hide=()=>{
        this.setState({
            show:false
        })
    }

    toDo=e=>{
        e.stopPropagation();
    }
    render() {

        if(!this.state.show){
            return <></>
        }

        return (
            <div className='modal-mask' onClick={this.hide}>
                <div className="modal-content" onClick={this.toDo}>
                    <div className="modal-head">
                        <div className="fs-20 fw-500">联系专属客服</div>
                        <i className="close-icon" onClick={this.hide} ></i>
                    </div>
                    <div className="modal-body text-c646566 fw-500 tac">
                        <img src="https://img.cxkoo.com/chengxuan/1/2022-12-12/786cb2f0d84beaad569c94336083d296.png" alt="" />
                        <div className="fs-16 mgt10">请使用微信或企业微信扫码联系我们</div>
                        <div className="fs-16 mgt10">工作日：9:00 ~ 20:00</div>
                    </div>
                </div>
            </div>
        )
    }
}
