import React, { Component } from 'react'
import Banner from '@/components/Banner'
import './index.scss'

export default class Educate extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
    }
    
    render() {
        return (
            <>
                <Banner no_swipe={true} current={1} ></Banner>
                <div className="wrapper educate">
                    <div className="fs-34 tac fw-600">覆盖教培行业的招生、教学、服务核心经营环节</div>
                    <div className="flex justify-between mgt40 step">
                        <div>
                            <div className="step1-icon"></div>
                            <div className="fs-20 mgt20">营销活动</div>
                        </div>
                        <div className="you-icon"></div>
                        <div>
                            <div className="step1-icon"></div>
                            <div className="fs-20 mgt20">招生拓客</div>
                        </div>
                        <div className="you-icon"></div>
                        <div>
                            <div className="step1-icon"></div>
                            <div className="fs-20 mgt20">学员管理</div>
                        </div>
                        <div className="you-icon"></div>
                        <div>
                            <div className="step1-icon"></div>
                            <div className="fs-20 mgt20">划分班级</div>
                        </div>
                        <div className="you-icon"></div>
                        <div>
                            <div className="step1-icon"></div>
                            <div className="fs-20 mgt20">教务管理</div>
                        </div>
                        <div className="you-icon"></div>
                        <div>
                            <div className="step1-icon"></div>
                            <div className="fs-20 mgt20">师生互动</div>
                        </div>

                    </div>
                    <div className="flex justify-between mgt65">
                        <div>
                            <p className='fs-26 fw-600 pdt10'>丰富的营销工具，让你招生不再困难</p>
                            <p className='active-detail'>公众号，朋友圈及社群通过老带新模式，搭配多种营销互工具及游戏，可以大幅提升学员活跃，让你招生、留存、转介绍不用愁。</p>
                        </div>
                        <div className='active-img1'></div>
                    </div>
                    <div className="flex justify-between mgt65">
                        <div className='active-img2'></div>
                        <div>
                            <p className='fs-26 fw-600 pdt10'>精细化管理，沉淀学员生命周期</p>
                            <p className='active-detail'>生日提醒、会员卡到期提醒、活跃会员、沉默会员等助力机构对学员精细化管理</p>
                        </div>
                    </div>
                    <div className="flex justify-between mgt65">
                        <div>
                            <p className='fs-26 fw-600 pdt10'>智能排课，课耗、满班不再发愁</p>
                            <p className='active-detail'>批量排课，系统自动判断是否有老师、教室的冲突<br />
                                多维度智能排课，上课日程一目了然<br />
                                灵活的签到消课方式、结合数据报表，节省人力。</p>
                        </div>
                        <div className='active-img3'></div>
                    </div>
                    <div className="flex justify-between mgt65">
                        <div className='active-img4'></div>
                        <div>
                            <p className='fs-26 fw-600 pdt10'>学员端轻松续课，查看课时</p>
                            <p className='active-detail'>学员可在小程序端随时查看上课记录，剩余课时数<br /> 打卡上课、请假申请，一键操作，方便快捷</p>
                        </div>
                    </div>
                    <div className="flex justify-between mgt65">
                        <div>
                            <p className='fs-26 fw-600 pdt10'>家校互联，学员更积极，家长更放心</p>
                            <p className='active-detail'>让学习更有仪式感<br />
                                家校通知、师生点评，连接家校、积累口碑</p>
                        </div>
                        <div className='active-img5'></div>
                    </div>
                </div>

            </>
        )
    }
}
