import React, { Component } from 'react'
import CountUp from 'react-countup'
import Banner from '@/components/Banner'
import './index.scss';

export default class Channels extends Component {

    componentDidMount(){
        window.scrollTo(0,0)
    }


    render() {
        return (
            <>
                <Banner no_swipe={true} current={2} ></Banner>
                <div className="wrapper channels">
                    <div className="fs-34 tac fw-600">视频号流量爆发，带来私域新增长</div>
                    <div className="fs-18 tac text-c484848 mgt10">微信视频号作为微信生态的链接器，通过打通社群、公众号、小商店、小程序生态，形成完整成熟的交易闭环，运营线下线上的私域流量。</div>
                    <div className="grow">
                        <div className="col">
                            <div className="flex justify-center align-center fs-38">
                                <i className="shang-icon"></i>
                                <span><CountUp start={0} end={80} duration={1} enableScrollSpy={true} />%</span>
                            </div>
                            <div className='fs-16 mgt20 tac'>
                                <p>视频号总用户使用时长</p>
                                <p>超过了朋友圈总用户使用时长</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="flex justify-center align-center fs-38">
                                <i className="shang-icon"></i>
                                <span><CountUp start={0} end={200} duration={1.4} enableScrollSpy={true} />%</span>
                            </div>
                            <div className='fs-16 mgt20 tac'>
                                <p>视频号总视频播放量</p>
                                <p>同比增长超过</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="flex justify-center align-center fs-38">
                                <i className="shang-icon"></i>
                                <span><CountUp start={200} end={400} duration={1.5} enableScrollSpy={true}  />%</span>
                            </div>
                            <div className='fs-16 mgt20 tac'>
                                <p>基于人工智能推荐的视频播放量</p>
                                <p>同比增长超过</p>

                            </div>
                        </div>
                        <div className="col">
                            <div className="flex justify-center align-center fs-38">
                                <i className="shang-icon"></i>
                                <span><CountUp start={0} end={100} duration={1} enableScrollSpy={true} />%</span>
                            </div>
                            <div className='fs-16 mgt20 tac'>
                                <p>日活跃创作者数和日均视频上传量</p>
                                <p>同比增长超过</p>
                            </div>
                        </div>
                    </div>
                    <div className="mgt50 text-c999 tac fs-14">数据来源：腾讯2022年Q2财报公布的数据</div>
                    <div className="fs-34 tac fw-600 pdt80">微信私域流量营销闭环</div>
                    <div className="mgt50 main_bg_1"></div>
                    <div className="fs-34 tac fw-600 mgt40">微信生态四大流量场景</div>
                    <div className="fs-18 tac text-c484848 mgt10">全面布局微信流量入口，加强曝光加速</div>
                    <div className="fs-18 tac text-c484848 mgt10">基于微信社交关系链，不干预流量上限，提供好友、公众号、社群、朋友圈、搜一搜等多种免费流量资源为视频号影流，高增裂变快</div>
                    <div className="tags mgt50">
                        <div className="tag">群聊、私聊</div>
                        <div className="tag">朋友圈</div>
                        <div className="tag">#tag进入视频号&话题</div>
                        <div className="tag">公众号</div>
                        <div className="tag">搜一搜</div>
                        <div className="tag">看一看</div>
                    </div>
                    <div className="mgt20 main_bg_2"></div>
                    <div className="fs-34 tac fw-600 pdt80">视频号解决方案，为商家经营开拓新场景</div>
                    <div className="fs-18 tac text-c484848 mgt10">澄瑄视屏号解决方案，助力商家从流量变现一站式经营，打造营销新生态。</div>
                    <div className="fs-18 tac text-c484848 mgt10">助力商家通过“公众号+社群+视频号直播+小商店+小程序”构建私域运营闭环，实现用户精准长效的转化留存。</div>
                    <div className="mgt20 main_bg_3"></div>
                </div>
            </>
        )
    }
}
